<template>
  <b-tabs pills card vertical class="inc-cpt-detail-all">
    <b-tab active v-if="configTabs.products">
      <template #title>
        <i class="fa fa-qrcode" title="Productos"></i>        
      </template>      
      <b-card-text>
        <DetailProducts :customer_id="customer_id" 
                        :price_list_id="price_list_id"
                        @load-detail="loadProducts($event)" 
                        :detailEdit="detail.products"
                        :editPrice="true"
                        :validStock="false"
                        :showDiscount="true"
                        :onlyDiscount="onlyDiscountProduct"/>
      </b-card-text>
    </b-tab>

    <b-tab v-if="configTabs.productsCompound">
      <template #title>
        <i class="fa fa-puzzle-piece" title="Productos Compuestos"></i>        
      </template>      
      <b-card-text>
        <DetailProductsCompound :customer_id="customer_id" 
                                :price_list_id="price_list_id"
                                @load-detail="loadProductsCompound($event)" 
                                :detailEdit="detail.productsCompound"
                                :editPrice="true"
                                :validStock="false"
                                :showDiscount="true"/>
      </b-card-text>
    </b-tab>

    <b-tab v-if="configTabs.services">
      <template #title>
        <i class="fa fa-rocket" title="Servicios"></i>        
      </template>         
      <b-card-text>
        <DetailServices :customer_id="customer_id" 
                        :price_list_id="price_list_id"  
                        @load-detail="loadServices($event)"
                        :details="detail.services"                                                
                        :isEdit="true"
                        :isInvoice="true"
                        :date_init="moment().format('MM-DD-YYYY')"/>        
      </b-card-text>
    </b-tab>
    
    <b-tab title="Conceptos" v-if="!hideConcepts">
      <template #title>
        <i class="fa fa-list" title="Conceptos"></i>        
      </template>         
      <b-card-text>
        <DetailConcepts :customer_id="customer_id" 
                        @load-detail="loadConcepts($event)"                        
                        :details="detail.concepts"             
                        :isEdit="true"/>     
      </b-card-text>
    </b-tab>
  </b-tabs>  
</template>
<script>
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import DetailProducts from '@/components/inc/cpbtDetail/detailProducts'  
  import DetailProductsCompound from '@/components/inc/cpbtDetail/detailProductsCompound'  
  import DetailServices from '@/components/inc/cpbtDetail/detailServices'  
  import DetailConcepts from '@/components/inc/cpbtDetail/detailConcepts'  
  import moment from 'moment'
  import Helper from '@/handler/helper'
  import Param from '@/config/parameters'

  export default {
    props: {
      customer_id:{
        type: Number,
        required: true,
      },
      price_list_id:{
        type: Number,
        default: 0,
      },      
      detailProducts:{
        type: [Object, Array],
        default: [],
      },      
      detailProductsCompound:{
        type: [Object, Array],
        default: [],
      },            
      detailServices:{
        type: [Object, Array],
        default: [],
      },      
      detailConcepts:{
        type: [Object, Array],
        default: [],
      },        
      hideConcepts: {
        type: Boolean,
        default: true,
      },
      onlyDiscountProduct: {
        type: Boolean,
        default: false,        
      }          
    },
    components: {      
      DetailProducts,  
      DetailProductsCompound,
      DetailServices,
      DetailConcepts,     
    }, 
    data: () => {
      return {          
        configTabs: {
          products: false,
          productsCompound: false,
          services: false,
          concepts: false,
        },
        detail: {
          products: [],
          productsCompound: [],
          services: [],
          concepts: [],
        },      
        detailReturn: [],
      }
    },
    mounted() {
      var modulesActive = Session.getSession().auth.user.permissions.modules
      
      modulesActive.forEach(element => {
        if(element.id == Modules.PRODUCTOS) {
          this.configTabs.products = true
          this.configTabs.productsCompound = Helper.hasParametersAccess(Param.P30)
        }

        if(element.id == Modules.SERVICIOS) {
          this.configTabs.services = true
        }                  
      })

      this.detail.products = this.detailProducts
      this.detail.productsCompound = this.detailProductsCompound
      this.detail.services = this.detailServices
      this.detail.concepts = this.detailConcepts      
    },         
    methods: {      
      loadProducts(event) {
        this.detail.products = event
        this.$emit('load-detail-products', this.detail.products)            
      },
      loadProductsCompound(event) {        
        this.detail.productsCompound = event
        this.$emit('load-detail-products-compound', this.detail.productsCompound)            
      },      
      loadServices(event) {
        this.detail.services = event
        this.$emit('load-detail-services', this.detail.services)            
      },
      loadConcepts(event) {
        this.detail.concepts = event
        this.$emit('load-detail-concepts', this.detail.concepts)            
      }
    }     
  }
</script>
<style>
  .inc-cpt-detail-all .card-header {
    padding: 0px;
  }
  .inc-cpt-detail-all .nav-link {
    padding: 5px 10px;
  }
</style>